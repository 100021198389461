import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import MobileDetect from "mobile-detect";

function Callback() {
  const { state } = useLocation();
  const {
    magicCredential,
    link,
    provider,
    magicState,
    scope,
    magicOAuthRequestId,
    metaData,
  } = state || {};

  const md = new MobileDetect(window.navigator.userAgent);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMobile) {
      try {
        if (link && link !== "") {
          window.location.href = generateLink();
        }
      } catch (e) {
        console.log("Error: " + e);
      }
    }
  }, [isMobile]);

  function init() {
    console.log("try callback init...");

    const os = md.os();

    setIsMobile(os === "iOS" || os === "AndroidOS");
  }

  function generateLink() {
    const magicCredentialEncoded = encodeURIComponent(magicCredential);
    const providerEncoded = encodeURIComponent(provider);
    const magicStateEncoded = encodeURIComponent(magicState); //.replaceAll(/~/g,"%7E");
    const scopeEncoded = encodeURIComponent(scope); //scope.replaceAll(/\s/g, "+");
    const magicOAuthRequestIdEncoded = encodeURIComponent(magicOAuthRequestId);

    const metaDataObj = metaData ? JSON.parse(metaData) : {};

    const verifierEncoded = encodeURIComponent(metaDataObj.verifier); //scope.replaceAll(/\s/g, "+");
    const metadataStateEncoded = encodeURIComponent(metaDataObj.state);

    return (
      link +
      `?magic_credential=${magicCredentialEncoded}&provider=${providerEncoded}&state=${magicStateEncoded}&scope=${scopeEncoded}&magic_oauth_request_id=${magicOAuthRequestIdEncoded}&verifier=${verifierEncoded}&metadata_state=${metadataStateEncoded}`
    );
  }

  return (
    <div>
      {link && link !== "" ? (
        <div>
          {isMobile ? (
            <a href={generateLink()} target="_blank" rel="noopener noreferrer">
              Open the App
            </a>
          ) : (
            <div>Open the App</div>
          )}
          <div>and wait for a while</div>
          <br />
        </div>
      ) : null}
      You can close this window.
    </div>
  );
}

export default Callback;
