enum OutboundMessageType {
  MAGIC_HANDLE_RESPONSE = "MAGIC_HANDLE_RESPONSE",
  MAGIC_OVERLAY_READY = "MAGIC_OVERLAY_READY",
  MAGIC_SHOW_OVERLAY = "MAGIC_SHOW_OVERLAY",
  MAGIC_HIDE_OVERLAY = "MAGIC_HIDE_OVERLAY",
  MAGIC_HANDLE_EVENT = "MAGIC_HANDLE_EVENT",
  MAGIC_IMX_READY = "MAGIC_IMX_READY",
}

enum InboundMessageType {
  MAGIC_HANDLE_REQUEST = "MAGIC_HANDLE_REQUEST",
}

enum MagicEventMethod {
  MAGIC_AUTH_LOGIN_WITH_LINK = "magic_auth_login_with_magic_link",
  MAGIC_AUTH_LOGIN_WITH_EMAIL_OTP = "magic_auth_login_with_email_otp",
  MAGIC_AUTH_VERIFY_EMAIL_OTP = "magic_auth_verify_email_otp",
  MAGIC_AUTH_LOGIN_WITH_CREDENTIAL = "magic_auth_login_with_credential",
  MAGIC_AUTH_REDIRECT_RESULT = "magic_auth_redirect_result",
  MAGIC_AUTH_GET_ID_TOKEN = "magic_auth_get_id_token",
  MAGIC_AUTH_GENERATED_ID_TOKEN = "magic_auth_generate_id_token",
  MAGIC_AUTH_GET_METADATA = "magic_auth_get_metadata",
  MAGIC_AUTH_IS_LOGGED_IN = "magic_auth_is_logged_in",
  MAGIC_AUTH_UPDATE_EMAIL = "magic_auth_update_email",
  MAGIC_AUTH_LOGOUT = "magic_auth_logout",
  ETH_ACCOUNTS = "eth_accounts",
  MAGIC_IMX_CHECK_IMX_BALANCE = "magic_imx_check_imx_balance",
  MAGIC_IMX_GET_ASSETS_I_OWN = "magic_imx_get_assets_i_own",
  MAGIC_IMX_TRANSFER_ETH = "magic_imx_transfer_eth",
  MAGIC_IMX_TRANSFER_NFT = "magic_imx_transfer_nft",
  MAGIC_IMX_BURN_NFT = "magic_imx_burn_nft",
}

export { InboundMessageType, OutboundMessageType, MagicEventMethod };
