// import logo from "./logo.svg";
import "./index.css";
import { useEffect, useState, useRef } from "react";
import { Magic, RPCError, RPCErrorCode } from "magic-sdk";
import { BigNumber, ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";

import { ImmutableX, Config } from "@imtbl/core-sdk";

import {
  Wallet,
  needToRegister,
  createETHDeposit,
  generateSpecificWalletConnection,
  getWalletBalance,
} from "../../utils/WalletConnection";

import { OAuthExtension, OAuthProvider } from "@magic-ext/oauth";

function Deposit() {
  const [ready, setReady] = useState<boolean>(false);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [deposit, setDeposit] = useState("");

  const [walletBalance, setWalletBalance] = useState({
    eth: "0.0",
    imxEth: "0.0",
  });

  const [depositInfo, setDepositInfo] = useState("");

  const magicRef = useRef<Magic<OAuthExtension[]>>();

  const immutableXClientRef = useRef<ImmutableX | null>();
  const walletRef = useRef<Wallet | null>(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      updateBalance();
    }
  }, [isLoggedIn]);

  const setCurrentWallet = async (magicProvider: any | null) => {
    if (magicProvider === null) {
      walletRef.current = null;
      return;
    }
    const provider = new Web3Provider(magicProvider);
    const signer = provider.getSigner();
    const address = (await signer.getAddress()).toLowerCase();
    const walletConnection = await generateSpecificWalletConnection(signer);

    console.log("Wallet address: " + address + " " + provider._isProvider);

    const cWallet: Wallet = {
      client: immutableXClientRef.current!,
      signer,
      provider,
      walletConnection,
      address,
    };

    walletRef.current = cWallet;
  };

  async function init() {
    const querySearch = new URLSearchParams(window.location.search);

    const pathName = window.location.pathname;

    const magicCredential = querySearch.get("magic_credential") ?? "";

    const apiKey = "pk_live_EFB1946C79BADEE2";
    const ethNetwork = pathName.includes("/deposit/test")
      ? "goerli"
      : "mainnet";

    await initMagicWallet({
      API_KEY: apiKey,
      ETH_NETWORK: ethNetwork,
    });

    if (magicCredential && magicCredential !== "") {
      magicAuthGetRedirectResult();
    }
  }

  async function initMagicWallet(params: any) {
    console.log("try initMagicWallet...");

    try {
      const { API_KEY, ETH_NETWORK } = params;

      if (API_KEY === undefined || ETH_NETWORK === undefined) {
        return;
      }

      const magic = new Magic(API_KEY, {
        network: ETH_NETWORK,
        extensions: [new OAuthExtension()],
      });

      const imxConfig =
        ETH_NETWORK === "mainnet" ? Config.PRODUCTION : Config.SANDBOX;

      console.log("imxConfig: " + JSON.stringify(imxConfig));

      const imxClient = new ImmutableX(imxConfig);

      // Setting Refs
      magicRef.current = magic;
      immutableXClientRef.current = imxClient;

      const isLoggedIn = await magic.user.isLoggedIn();
      if (isLoggedIn) {
        await onLoginSuccess();
      } else {
        setIsLoggedIn(isLoggedIn);

        setReady(true);
      }
    } catch (error) {
      // handle this
      console.log("error : " + error);
    }
  }

  async function updateBalance() {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      // Get user's balance in ether
      const magicBalance = ethers.utils.formatEther(
        await wallet.provider.getBalance(wallet.address) // Balance is in wei
      );

      // Get user's IMX balance in wei
      const balance = await getWalletBalance(wallet);
      // convert wei to eth
      const imxBalance = ethers.utils.formatEther(
        BigNumber.from(balance.balance)
      );

      setWalletBalance({ eth: magicBalance, imxEth: imxBalance });
    } catch (error) {
      // throw new Error(JSON.stringify(error, null, 4));
    }
  }

  async function magicAuthLoginWithMagicLink(id: number, params: any) {
    const { email, showUI, redirectURI } = params;

    const magic: Magic<OAuthExtension[]> = magicRef.current!;

    try {
      const isLoggedIn = await magic.user.isLoggedIn();

      let token: string | null = null;

      if (isLoggedIn) {
        const metaData = await magic.user.getMetadata();

        if (metaData.email === email) {
          token = await magic.user.getIdToken();
        }
      }

      if (token === null) {
        if (showUI) {
          // signalShow();
        }

        token = await magic.auth.loginWithMagicLink({
          email,
          showUI,
          redirectURI:
            redirectURI && redirectURI != ""
              ? window.location.origin + "?link=" + redirectURI
              : undefined,
        });
      }

      if (token !== null) {
        onLoginSuccess();
      }
    } catch (error) {
      if (error instanceof RPCError) {
        switch (error.code) {
          // case RPCErrorCode.MagicLinkFailedVerification:
          // case RPCErrorCode.MagicLinkExpired:
          // case RPCErrorCode.MagicLinkRateLimited:

          case RPCErrorCode.UserAlreadyLoggedIn: {
            // Handle errors accordingly :)
            const token = await magic.user.getIdToken();

            onLoginSuccess();
            break;
          }

          default: {
            break;
          }
        }
      } else {
        console.log("error : " + error);
      }
    }
  }

  async function magicAuthLogout() {
    const magic: Magic<OAuthExtension[]> = magicRef.current!;

    try {
      setReady(false);
      const bool = await magic.user.logout();

      setCurrentWallet(null);

      setIsLoggedIn(false);
      setReady(true);
    } catch (error) {
      if (error instanceof RPCError) {
      }
    }
  }

  async function magicAuthGetRedirectResult() {
    const magic: Magic<OAuthExtension[]> = magicRef.current!;

    try {
      setReady(false);

      const metaData = await magic.oauth.getOAuthMetadata();

      const queryString = window.location.search;

      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.replaceState(null, "", urlWithoutQuery);

      const result = await magic.oauth.getRedirectResult(queryString, metaData);

      console.log("result : " + JSON.stringify(result));

      const token = result.magic.idToken;

      if (token !== null) {
        onLoginSuccess();
      } else {
        setReady(true);
      }
    } catch (error) {
      console.log("error : " + error);
    }
  }

  //window.location.origin + "?link=" + redirectURI
  /* 'google', 'facebook', 'apple', or 'github' */
  async function magicAuthLoginWithRedirect(provider: OAuthProvider) {
    const magic: Magic<OAuthExtension[]> = magicRef.current!;

    try {
      setReady(false);

      const isLoggedIn = await magic.user.isLoggedIn();

      let token: string | null = null;

      if (isLoggedIn) {
        const metaData = await magic.user.getMetadata();

        if (metaData.email === email) {
          token = await magic.user.getIdToken();
        }
      }

      if (token === null) {
        await magic.oauth.loginWithRedirect({
          provider: provider,
          redirectURI: window.location.href,
        });
      } else {
        onLoginSuccess();
      }
    } catch (error) {
      if (error instanceof RPCError) {
        switch (error.code) {
          // case RPCErrorCode.MagicLinkFailedVerification:
          // case RPCErrorCode.MagicLinkExpired:
          // case RPCErrorCode.MagicLinkRateLimited:

          case RPCErrorCode.UserAlreadyLoggedIn: {
            // Handle errors accordingly :)
            const token = await magic.user.getIdToken();

            onLoginSuccess();
            break;
          }

          default: {
            break;
          }
        }
      } else {
        console.log("error : " + error);
      }
    }
  }

  async function onLoginSuccess() {
    await setCurrentWallet(magicRef.current!.rpcProvider);

    await connectWithIMX();

    setIsLoggedIn(true);

    setReady(true);
  }

  async function connectWithIMX() {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      await needToRegister(wallet);
    } catch (error) {
      throw new Error(JSON.stringify(error, null, 4));
    }
  }

  //Deposit ETH to IMX
  async function depositETHtoIMX(amount: string): Promise<void> {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      setReady(false);

      //make sure wallet is registered before doing something
      await needToRegister(wallet);

      const amount_to_deposit = `${ethers.utils.parseEther(amount)}`;
      console.log("try depositETHtoIMX: " + amount_to_deposit);

      const result = await createETHDeposit(wallet, amount_to_deposit);

      console.log("createETHDeposit: " + JSON.stringify(result));

      // const {tx, amount} = result;

      setDepositInfo(
        `hash: ${result.tx} amount: ${ethers.utils.formatEther(result.amount)}`
      );
    } catch (error) {
      if (error instanceof Error) {
        // ✅ TypeScript knows err is Error
        console.log("Deposit Error: > " + error.message);
        setDepositInfo(error.message);
      } else {
        console.log("Deposit Unexpected error", error);
        setDepositInfo(`${error}`);
      }
    }

    setReady(true);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    magicAuthLoginWithMagicLink(0, { email, showUI: true });
  };

  const handleDeposit = async (event: any) => {
    event.preventDefault();

    if (deposit != "") {
      if (parseFloat(deposit)) {
        console.log("deposit: " + deposit);
        await depositETHtoIMX(deposit);
      } else {
        alert("Invalid deposit");
      }

      setDeposit("");
    }
  };

  const renderHeader = () => {
    const address = walletRef.current?.address;
    const shortAddress = address
      ? `${address.slice(0, 6)}...${address.slice(
          address.length - 4,
          address.length
        )}`
      : null;

    return (
      <div className="header">
        <img
          className="logo"
          alt="Wagmi games"
          src={process.env.PUBLIC_URL + "/wagmi-logo.png"}
          decoding="async"
          data-nimg="intrinsic"
          // style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
          // srcset="/_next/image?url=%2Fimages%2Fwagmi_logo.png&amp;w=128&amp;q=75 1x, /_next/image?url=%2Fimages%2Fwagmi_logo.png&amp;w=256&amp;q=75 2x"
        />
        {address && (
          <div className="walletContainer">
            <div className="walletBalance">
              <img
                className="ethIcon"
                src={process.env.PUBLIC_URL + "/eth-icon.png"}
              />
              {`${
                walletBalance.eth.length > 10
                  ? walletBalance.eth.slice(0, 10) + "..."
                  : walletBalance.eth
              }`}
            </div>
            <button
              className="walletAddressButton"
              onClick={async () => {
                await navigator.clipboard.writeText(address);

                alert("Copied the text: " + address);
              }}
            >
              <img
                className="walletIcon"
                src={process.env.PUBLIC_URL + "/wallet-icon.png"}
              />

              <div className="wallet">{shortAddress}</div>
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderSignup = () => {
    return (
      <>
        {renderHeader()}
        <div className="deposit-content">
          {/* <h1>Please sign up or login</h1> */}
          <h1>Please login</h1>
          <form onSubmit={handleSubmit}>
            {/* <h1 className="form-title">Enter your email</h1>
            <input
              type="email"
              name="email"
              required={true}
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Send</button> */}

            {/* <h1 className="form-sub-title">to continue</h1> */}

            <button
              className="google-button"
              type="button"
              onClick={() => {
                magicAuthLoginWithRedirect("google");
              }}
            >
              Login with Google
            </button>
            <button
              className="apple-button"
              type="button"
              onClick={() => {
                magicAuthLoginWithRedirect("apple");
              }}
            >
              Login with Apple
            </button>
          </form>
        </div>
      </>
    );
  };

  const renderEthDeposit = () => {
    return (
      <>
        {renderHeader()}
        <div className="deposit-content">
          <h2>Current IMX Balance</h2>
          <div className="balance">{walletBalance.imxEth} ETH</div>
          <form onSubmit={handleDeposit}>
            <span>
              <h2>Deposit</h2>
              <h2>Tranfer ETH into IMX</h2>
            </span>
            <input
              type="number"
              name="deposit"
              step="0.01"
              value={deposit}
              required={true}
              placeholder="0"
              onChange={(e) => setDeposit(e.target.value)}
            />
            <button type="submit">Deposit</button>
          </form>
        </div>

        <button className="generalButton" onClick={() => magicAuthLogout()}>
          Logout
        </button>
      </>
    );
  };

  const renderLoader = () => {
    return (
      <>
        {renderHeader()}
        <div className="info-content">
          <h1>Please Wait...</h1>
        </div>
      </>
    );
  };

  const renderDepositInfo = () => {
    return (
      <>
        {renderHeader()}
        <div className="deposit-content">
          <h1>{depositInfo}</h1>
        </div>
        <button
          className="generalButton"
          onClick={() => {
            setDepositInfo("");
          }}
        >
          Deposit Again
        </button>
        <button className="generalButton" onClick={() => magicAuthLogout()}>
          Logout
        </button>
      </>
    );
  };

  return (
    <div className="deposit-container">
      {ready
        ? isLoggedIn
          ? depositInfo === ""
            ? renderEthDeposit()
            : renderDepositInfo()
          : renderSignup()
        : renderLoader()}
    </div>
  );
}

export default Deposit;
