// import logo from "./logo.svg";
import "./index.css";
import { useEffect, useState, useRef } from "react";
import { Base64 } from "js-base64";
import { Magic, RPCError } from "magic-sdk";
import { OAuthExtension, OAuthProvider } from "@magic-ext/oauth";

import { useNavigate } from "react-router-dom";

function SocialLogin() {
  const [isDebug, setIsDebug] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const magicRef = useRef<Magic<OAuthExtension[]>>();
  const querySearchParamsRef = useRef<string | null>();

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const querySearch = new URLSearchParams(window.location.search);

    const querySearchParams = querySearch.get("params") ?? "";
    const redirect = querySearch.get("redirect") ?? "";
    const debug = (querySearch.get("debug") ?? "false") === "true";

    const oauthProvider: string = querySearch.get("provider") ?? "";

    if (querySearchParams && querySearchParams !== "") {
      querySearchParamsRef.current = querySearchParams;
      // {"host":"http://localhost:3000","sdk":"magic-sdk-unity","API_KEY":"pk_live_EFB1946C79BADEE2","locale":"en-US","bundleId":"com.DefaultCompany.MagicLinkTest","ETH_NETWORK":"goerli"}
      const params = JSON.parse(Base64.decode(querySearchParams));

      console.log("params: " + JSON.stringify(params));

      await initMagicWallet(params, debug);

      magicAuthLoginWithRedirectString(oauthProvider, redirect);
    } else if (window.location.hostname === "localhost") {
      querySearchParamsRef.current = ""; // for testing
      //Wagmi
      await initMagicWallet(
        { API_KEY: "pk_live_EFB1946C79BADEE2", ETH_NETWORK: "goerli" },
        true
      );

      magicAuthLoginWithRedirectString(oauthProvider, redirect);
    } else {
      navigate("no_page");
    }
  }

  async function initMagicWallet(params: any, debug: boolean) {
    console.log("try initMagicWallet...");

    try {
      const { API_KEY, ETH_NETWORK } = params;

      if (API_KEY === undefined || ETH_NETWORK === undefined) {
        return;
      }

      setIsLoading(true);

      const magic = new Magic(API_KEY, {
        network: ETH_NETWORK,
        extensions: [new OAuthExtension()],
      });

      // Setting Refs
      magicRef.current = magic;

      // setting states
      setIsDebug(debug);

      const isLoggedIn = await magic.user.isLoggedIn();

      setIsLoggedIn(isLoggedIn);
      setIsLoading(false);
    } catch (error) {
      // handle this
      console.log("error : " + error);
    }
  }

  function magicAuthLoginWithRedirectString(
    authProvider: string,
    redirectURI: String
  ) {
    if (
      authProvider === "google" ||
      authProvider === "apple" ||
      authProvider === "facebook"
    ) {
      // setTimeout(() => {
      magicAuthLoginWithRedirect(authProvider, redirectURI);
      // }, 200);
    }
  }

  //window.location.origin + "?link=" + redirectURI
  /* 'google', 'facebook', 'apple', or 'github' */
  async function magicAuthLoginWithRedirect(
    provider: OAuthProvider,
    redirectURI: String
  ) {
    const magic: Magic<OAuthExtension[]> = magicRef.current!;

    try {
      setIsLoading(true);

      if (isLoggedIn) {
        // const metaData = await magic.user.getMetadata();
        // if (metaData.email === email) {
        //   token = await magic.user.getIdToken();
        // }

        await magic.user.logout();
      }

      await magic.oauth.loginWithRedirect({
        provider: provider,
        redirectURI:
          window.location.origin +
          "?link=" +
          redirectURI +
          "&params=" +
          querySearchParamsRef.current,
      });
    } catch (error) {
      console.log("error : " + error);
    }
  }

  async function magicAuthGetMetadata(id: number) {
    const magic = magicRef.current!;

    try {
      const userMetaData = await magic.user.getMetadata();
      console.log("userMetaData: ", userMetaData);
    } catch (error) {
      if (error instanceof RPCError) {
      }
    }
  }

  async function magicAuthLogout(id: number) {
    const magic = magicRef.current!;

    try {
      await magic.user.logout();

      setIsLoggedIn(false);
    } catch (error) {
      if (error instanceof RPCError) {
      }
    }
  }

  if (isLoading) {
    return (
      <div className="container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="container">
      {isLoggedIn != null && isDebug ? (
        !isLoggedIn ? (
          <div className="loginContainer">
            <h1>Please sign up or login</h1>
            <button
              onClick={() => {
                magicAuthLoginWithRedirect("google", "");
              }}
            >
              Google Login
            </button>
            <button
              onClick={() => {
                magicAuthLoginWithRedirect("apple", "");
              }}
            >
              Apple Login
            </button>
          </div>
        ) : (
          <>
            <button onClick={() => magicAuthLogout(0)}>Logout</button>
            <button onClick={() => magicAuthGetMetadata(0)}>
              Get Metadata
            </button>
          </>
        )
      ) : null}
    </div>
  );
}

export default SocialLogin;
