// import logo from "./logo.svg";
import "./index.css";
import React, { useEffect, useState, useRef } from "react";
import { Base64 } from "js-base64";
import { Magic, RPCError, RPCErrorCode } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";

import { BigNumber, ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import {
  InboundMessageType,
  OutboundMessageType,
  MagicEventMethod,
} from "../../enums";

import { ImmutableX, Config } from "@imtbl/core-sdk";

import {
  Wallet,
  needToRegister,
  getAssets,
  createETHTransfer,
  createETHDeposit,
  createERC721Transfer,
  generateSpecificWalletConnection,
  getWalletBalance,
  sellERC721ForETH,
  buyOrder,
} from "../../utils/WalletConnection";

import { useNavigate } from "react-router-dom";

const PHANTOM_AUTH_URL = "https://auth.fortmatic.com";
const MAGIC_AUTH_URL = "https://auth.magic.link";

function Home() {
  const [isDebug, setIsDebug] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [email, setEmail] = useState("");

  const magicRef = useRef<Magic<OAuthExtension[]>>();

  const querySearchParamsRef = useRef<string | null>();
  const immutableXClientRef = useRef<ImmutableX | null>();
  const walletRef = useRef<Wallet | null>(null);
  const loginWithEmailOTPEventsRef = useRef<any | null>(null);
  const loginOTPVerifyCountRef = useRef<number>(0);
  const rpcIdRef = useRef<number>(0);

  const isInitializedRef = useRef<boolean>(false);
  const isReadyRef = useRef<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  const setCurrentWallet = async (magicProvider: any | null) => {
    if (magicProvider === null) {
      walletRef.current = null;
      return;
    }
    const provider = new Web3Provider(magicProvider);
    const signer = provider.getSigner();
    const address = (await signer.getAddress()).toLowerCase();
    const walletConnection = await generateSpecificWalletConnection(signer);

    console.log("Wallet address: " + address + " " + provider._isProvider);

    const cWallet: Wallet = {
      client: immutableXClientRef.current!,
      signer,
      provider,
      walletConnection,
      address,
    };

    walletRef.current = cWallet;
  };

  async function init() {
    console.log("v3");

    const querySearch = new URLSearchParams(window.location.search);

    const querySearchParams = querySearch.get("params") ?? "";
    const debug = (querySearch.get("debug") ?? "false") === "true";

    const magicCredential = querySearch.get("magic_credential") ?? "";

    if (querySearchParams && querySearchParams !== "") {
      querySearchParamsRef.current = querySearchParams;
      // {"host":"http://localhost:3000","sdk":"magic-sdk-unity","API_KEY":"pk_live_EFB1946C79BADEE2","locale":"en-US","bundleId":"com.DefaultCompany.MagicLinkTest","ETH_NETWORK":"goerli"}
      const params = JSON.parse(Base64.decode(querySearchParams));

      console.log("params: " + JSON.stringify(params));

      await initMagicWallet(params, debug);

      await handleCallback(querySearch);
    } else if (window.location.hostname === "localhost") {
      querySearchParamsRef.current = ""; // for testing
      //Wagmi
      await initMagicWallet(
        { API_KEY: "pk_live_EFB1946C79BADEE2", ETH_NETWORK: "goerli" },
        true
      );

      // Test Either Api
      // initMagicWallet(
      //   { API_KEY: "pk_live_57590BC40822BC0B", ETH_NETWORK: "goerli" },
      //   true
      // );

      await handleCallback(querySearch);
    } else if (magicCredential && magicCredential !== "") {
      await handleCallback(querySearch);
    } else {
      navigate("no_page");
    }
  }

  async function initMagicWallet(params: any, debug: boolean) {
    console.log("try initMagicWallet...");

    try {
      const { API_KEY, ETH_NETWORK } = params;

      if (API_KEY === undefined || ETH_NETWORK === undefined) {
        return;
      }

      setIsLoading(true);

      const magic = new Magic(API_KEY, {
        network: ETH_NETWORK,
        extensions: [new OAuthExtension()],
      });

      const imxConfig =
        ETH_NETWORK === "mainnet" ? Config.PRODUCTION : Config.SANDBOX;

      console.log("imxConfig: " + JSON.stringify(imxConfig));

      const imxClient = new ImmutableX(imxConfig);

      // Setting Refs
      magicRef.current = magic;
      immutableXClientRef.current = imxClient;

      // setting states
      setIsDebug(debug);

      // signalHide();
      addEventListeners();
      // signalReady();

      const isLoggedIn = await magic.user.isLoggedIn();
      if (isLoggedIn) {
        await setCurrentWallet(magicRef.current!.rpcProvider);

        await connectWithIMX();
      }

      setIsLoggedIn(isLoggedIn);

      setIsLoading(false);

      isInitializedRef.current = true;

      signalReady();
    } catch (error) {
      // handle this
      signalHide();
      console.log("error : " + error);
    }
  }

  async function handleCallback(querySearch: URLSearchParams) {
    const magicCredential = querySearch.get("magic_credential") ?? "";

    if (magicCredential !== "") {
      const link = querySearch.get("link") ?? "";

      const provider = querySearch.get("provider") ?? "";
      const magicState = querySearch.get("state") ?? "";
      const scope = querySearch.get("scope") ?? "";
      const magicOAuthRequestId =
        querySearch.get("magic_oauth_request_id") ?? "";

      const metaData =
        provider != "" ? await magicAuthGetRedirectMetadata() : "";

      navigate("callback", {
        state: {
          link,
          magicCredential,
          provider,
          magicState,
          scope,
          magicOAuthRequestId,
          metaData,
        },
      });
    }
  }

  function addEventListeners() {
    window.addEventListener("message", async (event: MessageEvent<any>) => {
      // console.log("addEventListener: ", event);

      if (event.data) {
        await handleMessageEvent(event);
      }
    });
  }

  async function handleMessageEvent(event: MessageEvent<any>) {
    //{"msgType":"MAGIC_HANDLE_REQUEST-eyJob3N0IjoiaHR0cDovL2xvY2FsaG9zdDozMDAwIiwic2RrIjoibWFnaWMtc2RrLXVuaXR5IiwiQVBJX0tFWSI6InBrX2xpdmVfRUZCMTk0NkM3OUJBREVFMiIsImxvY2FsZSI6ImVuLVVTIiwiYnVuZGxlSWQiOiJjb20uRGVmYXVsdENvbXBhbnkuTWFnaWNMaW5rVGVzdCIsIkVUSF9ORVRXT1JLIjoiZ29lcmxpIn0=",
    // "payload":{"id":78146,"jsonrpc":"2.0","method":"magic_auth_login_with_magic_link", "params":[{"showUI":true,"email":"shoaib@cubixlabs.com"}]}}

    const messageData = event.data;

    if (!messageData || !messageData?.msgType) {
      return;
    }

    const isMsgType = (msgType: string) =>
      messageData?.msgType.includes(msgType);

    const isPhantomAuthMessage =
      event.origin === PHANTOM_AUTH_URL && isMsgType("FORTMATIC");
    const isMagicAuthMessage =
      event.origin === MAGIC_AUTH_URL && isMsgType("MAGIC");
    // const isMobileSDKMessage = event.origin === "";

    /**
     * Handler for message from Auth.
     * Also filter messages that doesn't belong to this auth relayer.
     * Auth Relayer -> Mgbox -> Mobile
     */
    console.log("RESPONSE Mgbox: ", messageData);
    if (isPhantomAuthMessage || isMagicAuthMessage) {
      // console.log("RESPONSE Mgbox <- Auth: ", messageData);

      if (
        isMsgType("MAGIC_OVERLAY_READY") ||
        isMsgType("FORTMATIC_OVERLAY_READY")
      ) {
        isReadyRef.current = true;

        signalReady();
      } else if (
        isMsgType("FORTMATIC_SHOW_OVERLAY") ||
        isMsgType("MAGIC_SHOW_OVERLAY")
      ) {
        signalShow();
      } else if (
        isMsgType("FORTMATIC_HIDE_OVERLAY") ||
        isMsgType("MAGIC_HIDE_OVERLAY")
      ) {
        signalHide();
      }

      // postToMobile(messageData);
    }

    if (messageData.msgType) {
      try {
        const msgTypeSplit: any[] = messageData.msgType.split("-");
        const msgType = msgTypeSplit[0];
        const queryParams = msgTypeSplit[1];

        if (
          msgType === InboundMessageType.MAGIC_HANDLE_REQUEST &&
          queryParams === querySearchParamsRef.current
        ) {
          const payload = messageData.payload;

          const { id, method, params } = payload;

          switch (method) {
            case MagicEventMethod.MAGIC_AUTH_LOGIN_WITH_LINK: {
              await magicAuthLoginWithMagicLink(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_LOGIN_WITH_EMAIL_OTP: {
              await magicAuthLoginWithEmailOTP(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_VERIFY_EMAIL_OTP: {
              await magicAuthVerifyEmailOTP(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_LOGIN_WITH_CREDENTIAL: {
              await magicAuthLoginWithCredential(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_REDIRECT_RESULT: {
              await magicAuthGetRedirectResult(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_GET_ID_TOKEN: {
              await magicAuthGetIdToken(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_GENERATED_ID_TOKEN: {
              await magicAuthGenerateIdToken(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_GET_METADATA: {
              await magicAuthGetMetadata(id);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_UPDATE_EMAIL: {
              // not handling for now
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_IS_LOGGED_IN: {
              await magicAuthIsLoggedIn(id);
              break;
            }

            case MagicEventMethod.MAGIC_AUTH_LOGOUT: {
              await magicAuthLogout(id);
              break;
            }

            case MagicEventMethod.ETH_ACCOUNTS: {
              await ethAccounts(id);
              break;
            }

            case MagicEventMethod.MAGIC_IMX_CHECK_IMX_BALANCE: {
              await checkIMXBalance(id);
              break;
            }

            case MagicEventMethod.MAGIC_IMX_GET_ASSETS_I_OWN: {
              await getAssetsIOwn(id);
              break;
            }

            case MagicEventMethod.MAGIC_IMX_TRANSFER_ETH: {
              await doETHTransfer(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_IMX_TRANSFER_NFT: {
              await doNFTTransfer(id, params[0]);
              break;
            }

            case MagicEventMethod.MAGIC_IMX_BURN_NFT: {
              await doNFTBurn(id, params[0]);
              break;
            }

            default: {
              // to noting
              break;
            }
          }
        }
      } catch (error) {
        // handle this
        // signalError(error);

        console.log("error : " + error);
      }
    }
  }

  async function magicAuthLoginWithMagicLink(id: number, params: any) {
    const { email, showUI, redirectURI } = params;

    const magic = magicRef.current!;

    try {
      const isLoggedIn = await magic.user.isLoggedIn();

      let token: string | null = null;

      if (isLoggedIn) {
        const metaData = await magic.user.getMetadata();

        if (metaData.email === email) {
          token = await magic.user.getIdToken();
        }
      }

      if (token === null) {
        if (showUI) {
          // signalShow();
        }
        // token = await magic.auth.loginWithMagicLink({
        //   email,
        //   showUI,
        //   redirectURI:
        //     redirectURI && redirectURI != ""
        //       ? window.location.origin + "?link=" + redirectURI
        //       : undefined,
        // });

        token = await magic.auth.loginWithEmailOTP({ email, showUI: true });
      }

      if (token !== null) {
        onLoginSuccess(id, token!);
      } else {
        signalHide();
        signalError(id, { code: 1, message: "Unknown Error" });
      }
    } catch (error) {
      if (error instanceof RPCError) {
        switch (error.code) {
          // case RPCErrorCode.MagicLinkFailedVerification:
          // case RPCErrorCode.MagicLinkExpired:
          // case RPCErrorCode.MagicLinkRateLimited:

          case RPCErrorCode.UserAlreadyLoggedIn: {
            // Handle errors accordingly :)
            const token = await magic.user.getIdToken();

            onLoginSuccess(id, token!);
            break;
          }

          default: {
            signalHide();
            signalError(id, error);
            break;
          }
        }
      } else {
        console.log("error : " + error);
      }
    }
    // }
  }

  async function magicAuthLoginWithEmailOTP(id: number, params: any) {
    const { email, showUI } = params;

    const magic = magicRef.current!;

    try {
      if (loginWithEmailOTPEventsRef.current !== null) {
        rpcIdRef.current = 0;
        loginWithEmailOTPEventsRef.current.emit("cancel");
        loginWithEmailOTPEventsRef.current = null;

        // await delay(200);
      }

      // const isLoggedIn = await magic.user.isLoggedIn();

      // if (isLoggedIn) {
      //   await magic.user.logout();
      // }

      const loginEvent = magic.auth.loginWithEmailOTP({ email, showUI });

      loginWithEmailOTPEventsRef.current = loginEvent;
      loginOTPVerifyCountRef.current = 0;

      loginEvent
        .on("email-otp-sent", () => {
          signalResult(id, "email-otp-sent");
        })
        .on("invalid-email-otp", () => {
          magicAuthHandleInvalidOTP();
        })
        .on("done", (result) => {
          // DID Token returned in result
          const didToken = result;

          if (rpcIdRef.current !== 0) {
            onLoginSuccess(rpcIdRef.current, didToken!);
          } else {
            onLoginSuccess(id, didToken!);
          }
        })
        .on("error", (reason) => {
          // is called if the Promise rejects
          console.error(reason);

          if (rpcIdRef.current !== 0) {
            const { code, rawMessage, message } = reason;
            signalError(rpcIdRef.current, {
              code,
              message: rawMessage ?? message,
            });
          }
        })
        .on("settled", () => {
          // is called when the Promise either resolves or rejects
        });
    } catch (error) {
      if (error instanceof RPCError) {
        if (showUI) {
          signalHide();
        }

        signalError(id, error);
      } else {
        console.log("error : " + error);
        signalError(id, { code: 1, message: "Unknown Error" });
      }
    }
  }

  const delay = (delayInMs: number) => {
    return new Promise((resolve) => setTimeout(resolve, delayInMs));
  };

  async function magicAuthVerifyEmailOTP(id: number, params: any) {
    if (loginWithEmailOTPEventsRef.current === null) {
      signalError(id, { code: 1, message: "No OTP found to verify." });
      return;
    }

    const { otp } = params;

    rpcIdRef.current = id;
    loginOTPVerifyCountRef.current++;

    loginWithEmailOTPEventsRef.current.emit("verify-email-otp", otp!);
  }

  function magicAuthHandleInvalidOTP() {
    if (loginWithEmailOTPEventsRef.current === null) {
      signalError(rpcIdRef.current, {
        code: 1,
        message: "No OTP found to verify.",
      });
      return;
    }

    if (loginOTPVerifyCountRef.current === 3) {
      // cancel login request
      loginWithEmailOTPEventsRef.current.emit("cancel");
      loginWithEmailOTPEventsRef.current = null;

      signalError(rpcIdRef.current, {
        code: 3,
        message: "Invalid OTP, Please login again!",
      });
    } else {
      signalError(rpcIdRef.current, {
        code: 2,
        message: "Invalid OTP, Please try again!",
      });
    }
  }

  async function magicAuthLoginWithCredential(id: number, params: any) {
    const { credential } = params;

    console.log("credential : " + credential);

    const magic = magicRef.current!;

    try {
      const token: string | null = await magic.auth.loginWithCredential(
        credential
      );

      console.log("token : " + token);

      if (token !== null) {
        onLoginSuccess(id, token!);
      } else {
        signalError(id, { code: 1, message: "Unknown Error" });
      }
    } catch (error) {
      console.log("error : " + error);
      if (error instanceof RPCError) {
        signalError(id, error);
      } else {
        console.log("error : " + error);
      }
    }
  }

  async function magicAuthGetRedirectMetadata() {
    setIsLoading(true);

    const magic = magicRef.current!;
    const metaData = await magic.oauth.getOAuthMetadata();

    setIsLoading(false);
    return metaData;
  }

  async function magicAuthGetRedirectResult(id: number, params: any) {
    const {
      magicCredential,
      provider,
      magicState,
      scope,
      magicOAuthRequestId,
      verifier,
      metadataState,
    } = params;

    const magicCredentialEncoded = encodeURIComponent(magicCredential);
    const providerEncoded = encodeURIComponent(provider);
    const magicStateEncoded = encodeURIComponent(magicState); //.replaceAll(/~/g,"%7E");
    const scopeEncoded = encodeURIComponent(scope); //scope.replaceAll(/\s/g, "+");
    const magicOAuthRequestIdEncoded = encodeURIComponent(magicOAuthRequestId);
    const verifierEncoded = encodeURIComponent(verifier); //scope.replaceAll(/\s/g, "+");
    const metadataStateEncoded = encodeURIComponent(metadataState);

    // const queryString = `?magic_credential=${magicCredential}&provider=${provider}&state=${magicState}&scope=${scope}&magic_oauth_request_id=${magicOAuthRequestId}`;
    const queryString = `?magic_credential=${magicCredentialEncoded}&provider=${providerEncoded}&state=${magicStateEncoded}&scope=${scopeEncoded}&magic_oauth_request_id=${magicOAuthRequestIdEncoded}`;

    const metaData = JSON.stringify({
      verifier: verifierEncoded,
      state: metadataStateEncoded,
    });

    console.log(`queryString: ${queryString}, metaData: ${metaData}`);

    const magic = magicRef.current!;

    try {
      const result = await magic.oauth.getRedirectResult(queryString, metaData);

      console.log("result : " + JSON.stringify(result));

      const token = result.magic.idToken;

      if (token !== null) {
        onLoginSuccess(id, token!);
      } else {
        signalError(id, { code: 1, message: "Unknown Error" });
      }
    } catch (error) {
      console.log("error : " + error);
      // if (error instanceof RPCError) {
      signalError(id, error);
      // } else {
      //   console.log("error : " + error);
      // }
    }
  }

  async function magicAuthGetIdToken(id: number, params: any) {
    const { lifespan, attachment } = params;

    const magic = magicRef.current!;

    try {
      const token = await magic.user.generateIdToken({ lifespan, attachment });
      signalResult(id, token);
    } catch (error) {
      if (error instanceof RPCError) {
        signalError(id, error);
      }
    }
  }

  async function magicAuthGenerateIdToken(id: number, params: any) {
    const { lifespan } = params;

    const magic = magicRef.current!;

    try {
      const token = await magic.user.getIdToken({ lifespan });
      signalResult(id, token);
    } catch (error) {
      if (error instanceof RPCError) {
        signalError(id, error);
      }
    }
  }

  async function magicAuthGetMetadata(id: number) {
    const magic = magicRef.current!;

    try {
      const userMetaData = await magic.user.getMetadata();
      console.log("userMetaData: ", userMetaData);
      signalResult(id, userMetaData);
    } catch (error) {
      if (error instanceof RPCError) {
        signalError(id, error);
      }
    }
  }

  async function magicAuthIsLoggedIn(id: number) {
    const magic = magicRef.current!;

    try {
      const isLoggedIn = await magic.user.isLoggedIn();
      signalResult(id, isLoggedIn);
    } catch (error) {
      if (error instanceof RPCError) {
        signalError(id, error);
      }
    }
  }

  async function magicAuthLogout(id: number) {
    const magic = magicRef.current!;

    try {
      const bool = await magic.user.logout();

      setCurrentWallet(null);

      signalResult(id, bool);

      setIsLoggedIn(false);
    } catch (error) {
      if (error instanceof RPCError) {
        signalError(id, error);
      }
    }
  }

  async function ethAccounts(id: number) {
    try {
      // handler this request
    } catch (error) {
      if (error instanceof RPCError) {
        signalError(id, error);
      }
    }
  }

  async function onLoginSuccess(rpcId: number, token: string) {
    console.log("onLoginSuccess: " + rpcId + " " + token);

    // await setCurrentWallet(magicRef.current!.rpcProvider as any);
    await setCurrentWallet(magicRef.current!.rpcProvider);

    await connectWithIMX();

    setIsLoggedIn(true);

    signalResult(rpcId, token);
    signalHide();
  }

  function signalReady() {
    if (isInitializedRef.current === true && isReadyRef.current === true) {
      console.log("... signalReady");
      postToUnity(OutboundMessageType.MAGIC_OVERLAY_READY);
    }
  }

  function signalHide() {
    postToUnity(OutboundMessageType.MAGIC_HIDE_OVERLAY);
  }

  function signalShow() {
    postToUnity(OutboundMessageType.MAGIC_SHOW_OVERLAY);
  }

  function signalIMXReady() {
    postToUnity(OutboundMessageType.MAGIC_IMX_READY);
  }

  function signalResult(rpcId: number, result: any) {
    postToUnity(OutboundMessageType.MAGIC_HANDLE_RESPONSE, rpcId, result);
  }

  function signalError(rpcId: number, error: any) {
    postToUnity(OutboundMessageType.MAGIC_HANDLE_RESPONSE, rpcId, null, error);
  }

  function postToUnity(
    msgType: string,
    rpcId: number = 0,
    result: any = null,
    error: any = null
  ): void {
    const querySearchParams = querySearchParamsRef.current;

    const json: string = JSON.stringify(
      result !== null
        ? {
            msgType: msgType + "-" + querySearchParams,
            response: { id: rpcId, result },
          }
        : error !== null
        ? {
            msgType: msgType + "-" + querySearchParams,
            response: { id: rpcId, error },
          }
        : { msgType: msgType + "-" + querySearchParams }
    );

    // console.log("postToUnity", json, querySearchParams);

    if (window.webkit) {
      window.webkit.messageHandlers.unityControl.postMessage(json);
    } else if (window.Unity) {
      window.Unity.call(json);
    } else {
      // Method is not found
    }
  }

  async function connectWithIMX() {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      await needToRegister(wallet);

      signalIMXReady();
    } catch (error) {
      throw new Error(JSON.stringify(error, null, 4));
    }
  }

  async function getMagicWalletBalance(id: number) {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }

    try {
      // Get user's balance in ether
      const balance = ethers.utils.formatEther(
        await wallet.provider.getBalance(wallet.address) // Balance is in wei
      );
      // showModal(balance, updateModal, toggle);

      signalResult(id, balance);

      console.log("balance: " + balance);
    } catch (error) {
      // throw new Error(JSON.stringify(error, null, 4));
      signalError(id, error);
    }
  }

  //Get IMX ETH balance of wallet
  async function checkIMXBalance(id: number) {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      signalError(id, {
        code: 0,
        message: "Wallet not initialized yet: ",
      });
      return;
    }
    try {
      const balance = await getWalletBalance(wallet);

      const ethBalance = ethers.utils.formatEther(
        BigNumber.from(balance.balance)
      );

      console.log(
        "balance: " + JSON.stringify(balance) + `, ethBalance ${ethBalance}`
      );

      signalResult(id, ethBalance);
    } catch (error) {
      // throw new Error(JSON.stringify(error, null, 4));
      signalError(id, error);
    }
  }

  //Write to console all asset in a wallet
  async function getAssetsIOwn(id: number) {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      const assets = await getAssets(wallet);
      // showModal(balance, updateModal, toggle);
      console.log("assets: " + JSON.stringify(assets));

      signalResult(id, assets);
    } catch (error) {
      console.error(error);
    }
  }

  //Transfer an ETH from users wallet to a target wallet
  async function doETHTransfer(id: number, params: any) {
    const { receiver, amount } = params;

    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      signalError(id, {
        code: 0,
        message: "Wallet not initialized yet: ",
      });
      return;
    }
    try {
      //check if target wallet has been registered
      await needToRegister(wallet);

      const amountToTransfer = `${ethers.utils.parseEther(amount)}`;

      console.log("try amountToTransfer: " + amountToTransfer);

      const result = await createETHTransfer(
        wallet,
        receiver,
        amountToTransfer
      );

      console.log("result: " + JSON.stringify(result));

      signalResult(id, result);
    } catch (error) {
      console.error(error);

      signalError(id, error);
    }
  }

  //Transfer an NFT from users wallet to a target wallet
  async function doNFTBurn(id: number, params: any) {
    const transferTo = "0x0000000000000000000000000000000000000000"; // Ethereum burn account

    return doNFTTransfer(id, { ...params, transferTo });
  }

  //Transfer an NFT from users wallet to a target wallet
  async function doNFTTransfer(id: number, params: any) {
    const { transferTo, tokenId, tokenAddress } = params;

    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      signalError(id, {
        code: 0,
        message: "Wallet not initialized yet: ",
      });
      return;
    }

    try {
      //check if target wallet has been registered
      await needToRegister(wallet);

      console.log("try nft transfer: " + tokenId);

      const result = await createERC721Transfer(
        wallet,
        tokenId,
        tokenAddress,
        transferTo
      );

      console.log("result: " + JSON.stringify(result));

      signalResult(id, result);
    } catch (error) {
      console.error(error);

      signalError(id, error);
    }
  }

  //Buy a specific IMX Order_ID
  async function doBuyOrder() {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      //make sure wallet is registered before doing something
      await needToRegister(wallet);
      const order_id_to_buy = 328;
      const result = await buyOrder(wallet, order_id_to_buy);
      // showModal(result, updateModal, toggle);
    } catch (error) {
      console.error(error);
    }
  }

  //Sell IMX ERC721 for ETH
  async function doSellERC721forETH() {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      //make sure wallet is registered before doing something
      await needToRegister(wallet);

      const token_address_for_sale =
        "0x7510f4d7bcaa8639c0f21b938662071c2df38c73";
      const token_id_for_sale = "152";
      //amount is quantized -
      const amount_eth_for_sale = "10000000000000000";
      const result = await sellERC721ForETH(
        wallet,
        token_id_for_sale,
        token_address_for_sale,
        amount_eth_for_sale
      );
      // showModal(result, updateModal, toggle);
    } catch (error) {
      console.error(error);
    }
  }

  //Deposit ETH to IMX
  async function depositETHtoIMX(id: number, amount: string) {
    const wallet = walletRef.current;

    if (!wallet) {
      console.log("Wallet not initialized yet");
      return;
    }
    try {
      //make sure wallet is registered before doing something
      await needToRegister(wallet);

      const amount_to_deposit = `${ethers.utils.parseEther(amount)}`;
      console.log("try depositETHtoIMX: " + amount_to_deposit);

      const result = await createETHDeposit(wallet, amount_to_deposit);

      console.log("createETHDeposit: " + JSON.stringify(result));

      // showModal(result, updateModal, toggle);

      signalResult(id, result);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    magicAuthLoginWithMagicLink(0, {
      email,
      showUI: true,
      redirectURI: "https://google.com",
    });
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="container">
      {isDebug ? (
        !isLoggedIn ? (
          <div className="loginContainer">
            <h1>Please sign up or login</h1>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                required={true}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit">Send</button>
            </form>
            <button
              onClick={() => {
                magicAuthLoginWithCredential(0, {
                  credential:
                    "WyIweGZkYjY2MTYxZTBlNjdhZmQwZjIxMTcwODRjODUyOTNlYTkwNzQxZTM3Mjk3ZmMxYzg2ZmIyOGY0ZmUzZTc3MjIyM2QxMDNkOTJhOTE1OTk1YzJlMzEyNzM0YzgwODE0OGVjMmMwNzJkYWFlM2NkZWIwYTJiM2ViMzM0NDZlZDM4MWMiLCJ7XCJpYXRcIjoxNjc3OTUwOTk3LFwiZXh0XCI6MTY3Nzk1MTg5NyxcImlzc1wiOlwiZGlkOmV0aHI6MHgxOGU3NEFEM2ViZEZlQThlQkE0NmFCOEMyYmQxNWEwNjJjZmVCMTVmXCIsXCJzdWJcIjpcIjZpNC1WajFHaXhDUElXcnpDR1hOempITVJIdHdhb0wyVENrNWRpSGlQOU09XCIsXCJhdWRcIjpcIlU1U0dxclhIdV93TVpjTEZpcFB5eEVNLXlKeUhTeTA4UlJKOXJ1aW9uN009XCIsXCJuYmZcIjoxNjc3OTUwOTk3LFwidGlkXCI6XCI4OWI3OTk1ZS0yNzI4LTQyMjMtOGExOS04YzMzOTdlMDA0NGNcIixcImFkZFwiOlwiMHg3YWIzMDIwMDNiYmYxYmRkNjc0ZWRlMWVhZjBjYzViZGNhZGNiOTI0N2RhOTdjMjFlMTE4ODEwNmQ2MDhmM2NjMjYzMTBlNjI0Y2Q4YjcwMTM1MWY4NzJiZjEyYjRiY2RkNDJkMGY2MjhhZmZjN2RmNTM0OTBmMzZkMjUxZjI1MzFjXCJ9Il0%3D",
                });
              }}
            >
              loginWithCredential
            </button>

            <button
              onClick={() => {
                magicAuthGetRedirectResult(0, {
                  provider: "google",
                  state:
                    "VS4IOQ9nFmMd1nClo5w35JpMz9eHmoP3czwrrbGNW5gsZYfhtlnrbHhUy-Iol2bKmsOd77IqnWSqPIzzJKDRW3doUREkks5g-HEYeduxr55or04wtwDzXHMj%7ERQ%7E3NY7",
                  scope: "openid+email+profile",
                  magic_oauth_request_id:
                    "YoUc6wY490-Z5Qtwnb_bSSMzYR3VBPS78hEKABct3tY%3D&magic_credential=WyIweGE0MTkxYWEwOWRmNWU3ZGUwMGQ2ZTU0NjQ2Yzk4OGE1MGJjNjZmMjIyMDQ4YWI2OGJlMGYzMDc1ZmI0ODJkYzQ2ODllNjRlODBiMTU1ZWRhNWU2YzUzZGE4NTQ5OTE0MGI3YzdhNTZiZWU0ODAzYWJlOGM1YTI3OTdkYmNjOTMwMWMiLCJ7XCJpYXRcIjoxNjc4MjY0NTU2LFwiZXh0XCI6MTY3ODI2NTQ1NixcImlzc1wiOlwiZGlkOmV0aHI6MHgxOGU3NEFEM2ViZEZlQThlQkE0NmFCOEMyYmQxNWEwNjJjZmVCMTVmXCIsXCJzdWJcIjpcIjZpNC1WajFHaXhDUElXcnpDR1hOempITVJIdHdhb0wyVENrNWRpSGlQOU09XCIsXCJhdWRcIjpcIlU1U0dxclhIdV93TVpjTEZpcFB5eEVNLXlKeUhTeTA4UlJKOXJ1aW9uN009XCIsXCJuYmZcIjoxNjc4MjY0NTU2LFwidGlkXCI6XCJhNGNkZDkyZS00ODgyLTRhN2EtODI1Yi1mY2EzOWQ5MTlmMDdcIixcImFkZFwiOlwiMHg1ZjNhNDFhYjNiM2IzZGMzYTIzZjAwNDRjN2Y1ZjY2ZDI5NTRjNWViM2FhM2NiOWY4MjBjMzU0MjcxNTRiMDE2MzQ5NzE0MDY4ZDAwMWQzZWJjYzlmZDZkZTk2ZDk5NTM2ZDdhZmIzM2EyMWE2ZTExMzJlM2UxM2VkNjM2ZGJmOTFjXCJ9Il0%3D",
                });
              }}
            >
              magicAuthGetRedirectResult
            </button>
          </div>
        ) : (
          <>
            <button onClick={() => magicAuthLogout(0)}>Logout</button>
            <button onClick={() => checkIMXBalance(0)}>Get Balance</button>
            <button onClick={() => magicAuthGetMetadata(0)}>
              Get Metadata
            </button>
            <button onClick={() => getAssetsIOwn(0)}>Get Assets I Own</button>
            <button onClick={() => getMagicWalletBalance(0)}>
              Get Magic Balance
            </button>
            <button onClick={() => depositETHtoIMX(0, "0.001")}>
              Deposit ETH to IMX
            </button>

            <button
              onClick={() =>
                doETHTransfer(0, {
                  receiver: "0x9c5aa82b2868246f67dd50cd776a4081277068ab",
                  amount: "0.001",
                })
              }
            >
              Do ETH Transfer
            </button>

            <button
              onClick={() =>
                doNFTBurn(0, {
                  tokenAddress: "0x5f65ebCBBD549a2877AFbD0047e8e094525835B1",
                  tokenId: "4808",
                })
              }
            >
              Burn NFT
            </button>
          </>
        )
      ) : null}
    </div>
  );
}

export default Home;
